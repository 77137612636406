import * as React from 'react'
import { ISharedStepProps } from './utils'

import Button from 'react-bootstrap/Button'
import { getInfluencers } from './Influencers'
import styles from './styles.module.scss'

const InfluencersVote: React.SFC<ISharedStepProps> = props => {
	const sendToPhonesAction = () => {
		const influencers = getInfluencers(props.state).map(c => c.client)
		props.actions.setStateOfParticipantsTo(
			influencers,
			'influencer_vote',
			'none'
		)

		props.actions.updateFirebaseAction()
	}

	return (
		<div className={styles.noTableSlide}>
			<h1>Give them a vote</h1>
			<Button size="lg" onClick={sendToPhonesAction}>
				It's Voting time
			</Button>
		</div>
	)
}

export default InfluencersVote
