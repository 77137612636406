import { IQuestionDefinition } from './types'

export const definitions: IQuestionDefinition[] = [
	{
		question: 'question-pleasant-handshake', // 0
		dataPoints: ['handshake']
	},
	{
		question: 'question-who-is-taller', // 1
		dataPoints: ['length']
	},
	{
		question: 'question-more-like-squirrel', // 2
		dataPoints: ['squirrel']
	},
	{
		question: 'question-more-like-you', // 3
		dataPoints: ['unique']
	},
	{
		question: 'question-more-beautiful-ears', // 4
		dataPoints: ['ears']
	},
	{
		question: 'question-higher-income', // 5
		dataPoints: ['income']
	},
	{
		question: 'question-more-attractive', // 6
		dataPoints: ['attractive']
	},
	{
		question: 'question-rather-babysit', // 7
		dataPoints: ['trust']
	},
	{
		question: 'question-live-longer', // 8
		dataPoints: ['survival']
	},
	{
		question: 'question-confess-secret', // 9
		dataPoints: ['trust']
	},
	{
		question: 'question-better-friend', // 10
		dataPoints: ['trust']
	},
	{
		question: 'question-donate-kidney', // 11
		dataPoints: ['survival']
	},
	{
		question: 'question-save-burning-building', // 12
		dataPoints: ['survival']
	}
]

export function definitionForQuestion(question: string) {
	return definitions.find(d => d.question === question)
}

// todo: how do we want to structure this?
const questions = [
	[
		definitions[0].question,
		definitions[1].question,
		definitions[2].question,
		definitions[3].question,
		definitions[4].question,
		definitions[5].question,
		definitions[6].question,
		definitions[7].question,
		definitions[8].question
	],
	[
		definitions[0].question,
		definitions[1].question,
		definitions[2].question,
		definitions[3].question,
		definitions[4].question,
		definitions[5].question,
		definitions[6].question,
		definitions[9].question,
		definitions[12].question
	],
	[
		definitions[0].question,
		definitions[1].question,
		definitions[2].question,
		definitions[3].question,
		definitions[4].question,
		definitions[5].question,
		definitions[6].question,
		definitions[10].question,
		definitions[11].question
	],
	[
		definitions[0].question,
		definitions[1].question,
		definitions[2].question,
		definitions[3].question,
		definitions[4].question,
		definitions[5].question,
		definitions[6].question,
		definitions[10].question,
		definitions[12].question
	]
]

export default questions
