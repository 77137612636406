import { combineReducers } from 'redux'
import AppReducer, { IAppState } from './app'
import FirebaseReducer, { IFirebaseState } from './firebase'

export interface IRootState {
	app: IAppState
	firebase: IFirebaseState
}

export const reducer = combineReducers({
	app: AppReducer,
	firebase: FirebaseReducer
})

export * from './app/actions'
export * from './firebase/actions'
export * from './actions'
