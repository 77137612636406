import * as React from 'react'
import { clientsSortedByDataPoint } from '../../analytics/algorithms'
import GenericReveal from '../GenericReveal'
import { ISharedStepProps } from './utils'

const Income: React.SFC<ISharedStepProps> = (props) => {
	const algorithmResult = clientsSortedByDataPoint(props.state, 'income')
	const sendToPhonesAction = () => {
		const sortedIds = algorithmResult.map((v) => v.client._id)
		props.actions.setPositionsFromSortedIdsAction(sortedIds)
		props.actions.updateFirebaseAction()
	}

	const totalParticipating = algorithmResult.length
	const oneThird = totalParticipating / 3
	const low = Math.round(oneThird * 2) + 1 + ' <-> ' + totalParticipating
	const mid = Math.round(oneThird) + 1 + ' <-> ' + Math.round(oneThird * 2)
	const high = '1 <-> ' + Math.round(oneThird)

	return (
		<div>
			<div
				style={{
					fontWeight: 'bold',
					fontSize: 35
				}}
			>
				<div
					style={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center'
					}}
				>
					{' '}
					Low: {low}{' '}
				</div>
				<div
					style={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center'
					}}
				>
					{' '}
					Mid: {mid}{' '}
				</div>
				<div
					style={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center'
					}}
				>
					{' '}
					High: {high}{' '}
				</div>
			</div>
			<GenericReveal
				name="On Income"
				results={algorithmResult}
				keysToShow={['income']}
				onClick={sendToPhonesAction}
			/>
		</div>
	)
}

export default Income
