import * as React from 'react'
import { IClientState } from '../../types'
import {
	clientsSortedByDataPoint,
	twoMostSimilar
} from '../../analytics/algorithms'
import { ISharedStepProps } from './utils'

import Button from 'react-bootstrap/Button'
import styles from './styles.module.scss'

export const getHandshakers = (state: IClientState[]) => {
	const tempResult = clientsSortedByDataPoint(state, 'handshake')
	const adamAndEve = twoMostSimilar(state)
	const algorithmResult = tempResult.filter(
		c =>
			adamAndEve.find(d => (d ? d._id === c.client._id : false)) ===
			undefined
	)
	const sendTo = algorithmResult.slice(0, 2)
	return sendTo.map(v => v.client)
}

const Handshakers: React.SFC<ISharedStepProps> = props => {
	const handshakers = getHandshakers(props.state)

	const sendToPhonesAction = () => {
		props.actions.setStateOfParticipantsTo(handshakers, 'green_screen')
		props.actions.updateFirebaseAction()
	}

	return (
		<div className={styles.noTableSlide}>
			<h1>Our best Winkers:</h1>
			<ul>
				{handshakers.map((r, index) => {
					const username = r && r.username ? r.username : 'Unknown'
					let pos = r.position
					if (pos === undefined) {
						pos = -1
					}
					return (
						<li key={username + index}>
							{username + ', Position: ' + (pos + 1)}
						</li>
					)
				})}
			</ul>
			<Button size="lg" onClick={sendToPhonesAction}>
				Give Green screen
			</Button>
		</div>
	)
}

export default Handshakers
