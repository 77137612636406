import * as React from 'react'
import { clientsSortedByDataPoint } from '../../analytics/algorithms'
import { ISharedStepProps } from './utils'

import Button from 'react-bootstrap/Button'

import styles from './styles.module.scss'

const Slowness: React.SFC<ISharedStepProps> = (props) => {
	const algorithmResult = clientsSortedByDataPoint(props.state, 'slowness')
	const s = algorithmResult.slice(0, 1).map((v) => v.client)
	const sendToPhonesAction = () => {
		props.actions.setStateOfParticipantsTo(s, 'blue_screen', 'none')
		props.actions.updateFirebaseAction()
	}
	const slowest = s[0]
	let slowestPosition = slowest.position

	if (slowestPosition === undefined) {
		slowestPosition = -1
	}

	return (
		<div className={styles.noTableSlide}>
			<h1>The Slowest</h1>
			<h3>{slowest ? slowest.username : `Unknown name`}</h3>
			<h4>Position: {slowestPosition + 1}</h4>
			<Button size="lg" onClick={sendToPhonesAction}>
				Give Blue screen
			</Button>
		</div>
	)
}

export default Slowness
