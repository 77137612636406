import * as React from 'react'
import { clientsSortedByDataPoint } from '../../analytics/algorithms'
import { ISharedStepProps } from './utils'

import Button from 'react-bootstrap/Button'

import { IClientState } from '../../types'
import styles from './styles.module.scss'

export const getMostSquirrle = (state: IClientState[]) => {
	const algorithmResult = clientsSortedByDataPoint(state, 'squirrel')
	const sendTo = algorithmResult.slice(0, 1)
	return sendTo.map(v => v.client)
}

const Squirrel: React.SFC<ISharedStepProps> = props => {
	const users = getMostSquirrle(props.state)
	const sendToPhonesAction = () => {
		props.actions.setStateOfParticipantsTo(users, 'yellow_screen', 'none')
		props.actions.updateFirebaseAction()
	}

	const sendPhotoToPhonesAction = () => {
		props.actions.setImageURLAction(
			'https://smartphoneorchestra.com/squirrelmeme.jpg'
		)
		props.actions.setStateOfParticipantsTo(props.state, 'image')

		props.actions.updateFirebaseAction()
	}

	const squirrel = users[0]
	let squirrelPosition = squirrel.position

	if (squirrelPosition === undefined) {
		squirrelPosition = -1
	}

	return (
		<div className={styles.noTableSlide}>
			<h1>The Most Squirrel</h1>
			<h3>{squirrel ? squirrel.username : `Unknown name`}</h3>
			<h3>Position: {squirrelPosition + 1}</h3>
			<Button size="lg" onClick={sendToPhonesAction}>
				Give Yellow screen
			</Button>
			<Button size="lg" onClick={sendPhotoToPhonesAction}>
				Release the squirrel photo
			</Button>
		</div>
	)
}

export default Squirrel
