import { AppActionTypes } from '../actions'
import { AppAction } from './actions'

export interface IAppState {
	connected: boolean
	refreshing: boolean
}

const initialState: IAppState = {
	connected: false,
	refreshing: false
}

export default (state = initialState, action: AppAction): IAppState => {
	switch (action.type) {
		case AppActionTypes.SET_CONNECTION_STATE: {
			const { connected } = action
			return { ...state, connected }
		}

		case AppActionTypes.SET_REFRESHING_STATE: {
			const { refreshing } = action
			return { ...state, refreshing }
		}

		default:
			return state
	}
}
