import { IClientState } from '../../types'
import { FirebaseActionTypes } from '../actions'
import { FirebaseAction } from './actions'

export type IFirebaseState = IClientState[]
const initialState: IFirebaseState = []

const mapParticipating = (
	state: IClientState[],
	fn: (client: IClientState) => IClientState
) => {
	return state.map(c => (c.participating ? fn(c) : c))
}

export default (
	state = initialState,
	action: FirebaseAction
): IFirebaseState => {
	switch (action.type) {
		case FirebaseActionTypes.UPDATE: {
			return action.data
		}

		case FirebaseActionTypes.SET_STATE_KEY_FOR_ID: {
			return state.map(client => {
				if (client._id === action.id) {
					return {
						...client,
						state: action.state
					}
				} else if (action.others) {
					return { ...client, state: action.others }
				} else {
					return client
				}
			})
		}

		case FirebaseActionTypes.SET_KEY_FOR_ID: {
			return state.map(client => {
				if (client._id === action.id) {
					return {
						...client,
						[action.key]: action.value
					}
				} else {
					return client
				}
			})
		}

		case FirebaseActionTypes.SET_POSITION_FROM_SORTED_IDS: {
			const { sortedIds } = action
			return mapParticipating(state, client => {
				const position = sortedIds.indexOf(client._id)
				if (position === -1) {
					console.error(`Position for ${client._id} is -1...`)
					return { ...client, position, state: 'none' }
				}
				return { ...client, position, state: 'sorted' }
			})
		}

		case FirebaseActionTypes.SET_STATE_OF_PARTICIPANTS: {
			return state.map(client => {
				const foundInRequestedParticipants = action.participants.find(
					p => !!p && p._id === client._id
				)
				if (foundInRequestedParticipants) {
					return { ...client, state: action.state }
				} else if (action.others) {
					return { ...client, state: action.others }
				} else {
					return client
				}
			})
		}

		case FirebaseActionTypes.SET_POSITION_FOR_ID: {
			return state.map(client => {
				if (client._id === action.id) {
					const { position } = action
					return { ...client, position }
				} else {
					return client
				}
			})
		}

		case FirebaseActionTypes.SET_MATCH_QUESTIONS_FOR_ID: {
			return state.map(client => {
				if (client._id === action.id) {
					const { matchQuestions } = action
					return { ...client, matchQuestions }
				} else {
					return client
				}
			})
		}

		case FirebaseActionTypes.SET_IMAGE_URL: {
			return state.map(client => {
				return { ...client, imageUrl: action.url }
			})
		}

		default:
			return state
	}
}
