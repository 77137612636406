import * as React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import {
	createRefreshLocalStoreAction,
	createSetImageURLAction,
	createSetMatchQuestionsForId,
	createSetPositionForId,
	createSetPositionsFromSortedIdsAction,
	createSetStateForIdKeyAction,
	createSetStateOfParticipantsTo,
	createUpdateFirebaseAction,
	createSetKeyForIdAction
} from '../../reducers/firebase/actions'

import { IRootState } from '../../reducers'

import AdamAndEve from './AdamAndEve'
import Consent from './Consent'
import Ears from './Ears'
import Handshakers from './Handshakers'
import Income from './Income'
import Influencers from './Influencers'
import InfluencersVote from './InfluencersVote'
import Shoesize from './Shoesize'
import Matches from './Matches'
import Secretive from './Secretive'
import Slowness from './Slowness'
import Squirrel from './Squirrel'
import SquirrelPhoto from './SquirrelPhoto'
import { IStepActions } from './utils'

export enum Steps {
	INVALID,
	INCOME,
	SHOESIZE,
	EARS,
	SLOWNESS,
	CONSENT,
	SECRETIVE,
	INFLUENCERS,
	INFLUENCERS_VOTE,
	SQUIRREL,
	SQUIRRELPHOTO,
	ADAMEVE,
	HANDSHAKERS,
	MATCHES
}

interface IStepManagerProps
	extends ReturnType<typeof mapStateToProps>,
		ReturnType<typeof mapDispatchToProps> {
	step: Steps
}

const StepManager: React.SFC<IStepManagerProps> = (props) => {
	const createRefreshLocalStoreAction = props.createRefreshLocalStoreAction
	React.useEffect(() => {
		createRefreshLocalStoreAction()
	}, [props.step, createRefreshLocalStoreAction])

	const actions: IStepActions = {
		setPositionsFromSortedIdsAction:
			props.createSetPositionsFromSortedIdsAction,
		updateFirebaseAction: props.createUpdateFirebaseAction,
		setStateOfParticipantsTo: props.createSetStateOfParticipantsTo,
		setStateForIdKeyAction: props.createSetStateForIdKeyAction,
		setKeyForIdAction: props.createSetKeyForIdAction,
		setPositionForId: props.createSetPositionForId,
		setImageURLAction: props.createSetImageURLAction,
		setMatchQuestionsForId: props.createSetMatchQuestionsForId
	}

	switch (props.step) {
		case Steps.INCOME:
			return <Income state={props.firebase} actions={actions} />
		case Steps.SHOESIZE:
			return <Shoesize state={props.firebase} actions={actions} />
		case Steps.EARS:
			return <Ears state={props.firebase} actions={actions} />
		case Steps.SLOWNESS:
			return <Slowness state={props.firebase} actions={actions} />
		case Steps.CONSENT:
			return <Consent state={props.firebase} actions={actions} />
		case Steps.SECRETIVE:
			return <Secretive state={props.firebase} actions={actions} />
		case Steps.INFLUENCERS:
			return <Influencers state={props.firebase} actions={actions} />
		case Steps.INFLUENCERS_VOTE:
			return <InfluencersVote state={props.firebase} actions={actions} />
		case Steps.SQUIRREL:
			return <Squirrel state={props.firebase} actions={actions} />
		case Steps.SQUIRRELPHOTO:
			return <SquirrelPhoto state={props.firebase} actions={actions} />
		case Steps.ADAMEVE:
			return <AdamAndEve state={props.firebase} actions={actions} />
		case Steps.HANDSHAKERS:
			return <Handshakers state={props.firebase} actions={actions} />
		case Steps.MATCHES:
			return <Matches state={props.firebase} actions={actions} />
		default:
			return null
	}
}

const mapStateToProps = (state: IRootState) => ({
	app: state.app,
	firebase: state.firebase
})

const mapDispatchToProps = (dispatch: Dispatch) => {
	return bindActionCreators(
		{
			createRefreshLocalStoreAction,
			createSetPositionsFromSortedIdsAction,
			createUpdateFirebaseAction,
			createSetStateOfParticipantsTo,
			createSetPositionForId,
			createSetStateForIdKeyAction,
			createSetKeyForIdAction,
			createSetImageURLAction,
			createSetMatchQuestionsForId
		},
		dispatch
	)
}

export default connect(mapStateToProps, mapDispatchToProps)(StepManager)
