import { random } from './utils'

export default class {
	private seeds: number[] = []

	constructor(size: number) {
		for (let i = 0; i < size; i++) {
			this.seeds.push(i)
		}
	}

	getNumber() {
		const arrayIndex = random(0, this.seeds.length - 1)
		const randomNumber = this.seeds[arrayIndex]
		this.seeds.splice(arrayIndex, 1)
		return randomNumber
	}

	clear() {
		this.seeds = [] // empty the array
	}

	getSeeds() {
		return this.seeds
	}
}
