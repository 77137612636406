import * as React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'

import Application from './components/Application'
import { configureStore } from './store'

import './styles.scss'

const store = configureStore()

ReactDOM.render(
	<Provider store={store}>
		<Application />
	</Provider>,
	document.getElementById('container')
)
