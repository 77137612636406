import { AppActionTypes, createAction } from '../actions'

export function createSetConnectionStateAction(connected: boolean) {
	return createAction({
		type: AppActionTypes.SET_CONNECTION_STATE,
		connected
	})
}

export function createSetRefreshingStateAction(refreshing: boolean) {
	return createAction({
		type: AppActionTypes.SET_REFRESHING_STATE,
		refreshing
	})
}

export type AppAction = ReturnType<
	| typeof createSetConnectionStateAction
	| typeof createSetRefreshingStateAction
>
