import * as React from 'react'
import { IDataPoint, IProfile } from '../../types'
import {
	IAlgorithmResult,
	ICorrelationResult
} from '../../analytics/algorithms'

import Table from 'react-bootstrap/Table'

import Button from 'react-bootstrap/Button'
import styles from './styles.module.scss'

interface IGenericRevealProps {
	name: string
	results: IAlgorithmResult[]
	keysToShow?: IDataPoint[]
	profileKeysToShow?: Array<keyof IProfile>
	correlations?: ICorrelationResult[]
	onClick?: () => void
}

const GenericReveal: React.SFC<IGenericRevealProps> = ({
	name,
	results,
	correlations = [],
	keysToShow = [],
	profileKeysToShow = [],
	onClick
}) => (
	<div>
		<div className={styles.header}>
			<h1>{name}</h1>
			<Button onClick={onClick}>Send to phones</Button>
		</div>
		<div className={styles.tableContainer}>
			<div className={styles.table}>
				<Table striped bordered hover size="sm">
					<thead>
						<tr>
							<td>Position</td>
							<td>Username</td>
							{keysToShow.map(key => (
								<td key={key}>{key}</td>
							))}
							{profileKeysToShow.map(key => (
								<td key={key}>{key}</td>
							))}
						</tr>
					</thead>
					<tbody>
						{results.map(({ client, points }, index) => (
							<tr key={client._id}>
								<td>{index + 1}</td>
								<td>{client.username}</td>
								{keysToShow.map(key => (
									<td key={client._id + key}>
										{points![key] || 0}
									</td>
								))}
								{profileKeysToShow.map(key => (
									<td key={client._id + key}>
										{client.profile![key] || 0}
									</td>
								))}
							</tr>
						))}
					</tbody>
				</Table>
			</div>
			{!!correlations.length && (
				<div className={styles.table}>
					<Table striped bordered hover size="sm">
						<thead>
							<tr>
								<td>Data Point</td>
								<td>Correlation</td>
							</tr>
						</thead>
						<tbody>
							{correlations.map(correlation => (
								<tr key={correlation.key}>
									<td>{correlation.key}</td>
									<td>
										{correlation.correlation.toFixed(2)}
									</td>
								</tr>
							))}
						</tbody>
					</Table>
				</div>
			)}
		</div>
	</div>
)

export default GenericReveal
