import { IClientState, IDataPoint } from '../types'
import { IAlgorithmResult, IClientPoints } from './algorithms'

export function random(low: number, high: number) {
	return Math.floor(Math.random() * (high - low + 1) + low)
}

export function onlyParticipating(state: IClientState[]) {
	return state.filter(c => c.participating)
}

export function clientWithId<T extends { _id: string }>(
	id: string,
	state: T[]
) {
	return state.find(c => c._id === id)
}

export function withoutClients(arr: IClientState[], toRemove: IClientState[]) {
	return arr.filter(
		c => toRemove.find(d => !!d && c._id === d._id) === undefined
	)
}

export function pointsAreSortedDesc(state: IClientPoints[]) {
	let v = Infinity
	for (const c of state) {
		if (c.points > v) {
			return false
		}
		v = c.points
	}
	return true
}

export function pointsAreSortedAsc(state: IClientPoints[]) {
	let v = 0
	for (const c of state) {
		if (c.points < v) {
			return false
		}
		v = c.points
	}
	return true
}

export function sortByPoint(
	arr: IAlgorithmResult[],
	key: IDataPoint,
	order: 'asc' | 'desc' = 'desc'
) {
	const sortedArray = [...arr]
	sortedArray.sort((ia, ib) => {
		const pointsA = ia.points[key] || 0
		const pointsB = ib.points[key] || 0
		return pointsA - pointsB
	})
	if (order === 'desc') {
		sortedArray.reverse()
	}
	return sortedArray
}

export function numbersRepeat(arr: number[]) {
	const firstValue = arr[0]
	for (let i = 1; i < arr.length; i++) {
		const v = arr[i]
		if (v !== firstValue) {
			return false
		}
	}
	return true
}

export function numberAlternates(arr: number[]) {
	let lastValue = arr[0]
	for (let i = 1; i < arr.length; i++) {
		const v = arr[i]
		if (v === lastValue) {
			return false
		}
		lastValue = v
	}
	return true
}

export function divide<T>(
	arr: T[],
	callback: (value: T) => boolean
): [T[], T[]] {
	return arr.reduce(
		(pv, cv) => {
			callback(cv) ? pv[0].push(cv) : pv[1].push(cv)
			return pv
		},
		[[], []] as [T[], T[]]
	)
}
