import * as React from 'react'
import { ISharedStepProps } from './utils'

import Button from 'react-bootstrap/Button'
import styles from './styles.module.scss'

const SquirrelPhoto: React.SFC<ISharedStepProps> = props => {
	const sendToPhonesAction = () => {
		props.actions.setImageURLAction(
			'https://smartphoneorchestra.com/squirrelmeme.jpg'
		)
		props.actions.setStateOfParticipantsTo(props.state, 'image')

		props.actions.updateFirebaseAction()
	}

	return (
		<div className={styles.noTableSlide}>
			<h1>Squirrels are nice</h1>
			<Button size="lg" onClick={sendToPhonesAction}>
				Release the squirrel photo
			</Button>
		</div>
	)
}

export default SquirrelPhoto
